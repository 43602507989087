import React from "react";
import "./loading.css";

const LoadingFull = ({ active }) => {
  return (
    <div
      className={`loading-full ${
        active ? "active" : ""
      } fixed top-0 left-0 w-full h-screen flex justify-center items-center`}
    >
      <div className="absolute bg-current opacity-75 top-0 left-0 w-full h-screen"></div>
      <div className="relative w-1/12">
        <img src="/app_logo.png" className="w-full" alt="" />
      </div>
    </div>
  );
};

export { LoadingFull };
