import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { LoadingFull } from "../components/loading/loading";

// Maps : pilih salah satu
// const Map = "../components/maps/google" //google map single map
// const Map = "../components/maps/leaflet-with-google-layer" //leaflet multi maps inprogress

const LayoutAdmin = React.lazy(() => import("../layouts/admin"));
const LayoutPublic = React.lazy(() => import("../layouts/public"));

function Router(props) {
  return (
    <Suspense fallback={<div className="bg-water-theme" />}>
      <Routes>
        <Route path="/admin/*" element={<LayoutAdmin />} />
        <Route path="*" element={<LayoutPublic />} />
      </Routes>
    </Suspense>
  );
}

export default Router;
